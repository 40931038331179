import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import { t } from 'locale';
import Locale from 'locale';
import { convertThaiDate } from 'utils/format';

const useStyles = makeStyles(() => ({
  root: {},
  image: {
    width: '100%',
    maxHeight: 400
  }
}));

const Header = props => {
  const { className, filterDate, setFilterDate, fetchData, ...rest } = props;

  const classes = useStyles();
  const session = useSelector(state => state.session);

  const handleChange = e => {
    // if (Locale.get()==='th') {
    //   setFilterDate(
    //     moment(e.target.value)
    //       .subtract(543, 'years')
    //       .format('YYYY-MM')
    //   );
    // } else {
      setFilterDate(e.target.value);
    // }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between" spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography component="h1" gutterBottom variant="h3">
            {t('distribution_management')}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            {t('good_morning')}, {session.user.local_name}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid
            container
            alignItems="center"
            spacing={1}
            justifyContent="flex-end">
            <Grid item>
              <TextField
                className={clsx(classes.field, classes.margin10)}
                color="primary"
                fullWidth
                label={t('filter_date')}
                name="filterDate"
                onChange={handleChange}
                type="month"
                variant="outlined"
                value={convertThaiDate(filterDate, 'YYYY-MM')}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                className={clsx(classes.button, classes.margin10)}
                variant="contained"
                onClick={() => fetchData()}>
                <SearchIcon /> {t('filter_button')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
