import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid } from '@material-ui/core';

import { formatMoneyText } from 'utils/format';
import { t } from 'locale';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  }
}));

const Statistics = props => {
  const { className, totalQuantity, totalPaid, ...rest } = props;

  const currentCurrency = useSelector(state => state.session.currency);

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justifyContent="space-between">
        <Grid className={classes.item} item md={6} sm={6} xs={12}>
          <Typography variant="h2">{totalQuantity}</Typography>
          <Typography className={classes.overline} variant="overline">
            {t('total_sell_quantity')}
          </Typography>
        </Grid>
        <Grid className={classes.item} item md={6} sm={6} xs={12}>
          <Typography variant="h2">
            {formatMoneyText(totalPaid, currentCurrency)}
          </Typography>
          <Typography className={classes.overline} variant="overline">
            {t('total_sales')}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

Statistics.propTypes = {
  className: PropTypes.string
};

export default Statistics;
