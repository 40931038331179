import { isString, replace } from 'lodash';
import localeEn from '../assets/lang/en';
import localeVi from '../assets/lang/vi';
import localeTh from '../assets/lang/th';

const getUserLocalesInternal = () => {
  return localStorage.getItem('tms-language') || 'en';
};

class LocaleService {
  currLocale = 'en';
  words = {};
  constructor() {
    const _locale = getUserLocalesInternal() || this.currLocale;
    this.currLocale = _locale;
    this.words = this.getLang(_locale);
  }

  getLang = locale => {
    switch (locale) {
      case 'vi':
        return localeVi;
      case 'th':
        return localeTh;
      default:
        return localeEn;
    }
  };

  get = () => this.currLocale

  setLang = locale => {
    localStorage.setItem('tms-language', locale);
    this.currLocale = locale;
    this.words = this.getLang(locale);
  };

  translate = (key, params) => {
    if (!key) return '';
    let str = this.words[key] || key;
    if (!params) return str;
    const paramsKeys = Object.keys(params);
    if (paramsKeys.length > 0) {
      // eslint-disable-next-line
      paramsKeys.map(key => {
        if (isString(str)) {
          str = replace(str, new RegExp(`{${key}}`, 'g'), params[key]);
        }
      });
      return String(str);
    }
    return String(str);
  };
}

const Locale = new LocaleService();
export const t = Locale.translate;
export const languageMap = {
  en: 'English',
  vi: 'Việt Nam',
  th: 'ไทย'
};
export default Locale;
