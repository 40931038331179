import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Page } from 'components';
import { Header, Statistics } from './components';
import { Grid } from '@material-ui/core';
import TableVertical from 'components/TableVertical';
import { dashboardReportApi } from 'apis';
import moment from 'moment';
import { t } from 'locale';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  statistics: {
    marginTop: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const Overview = () => {
  const classes = useStyles();

  const [filterDate, setFilterDate] = React.useState(new Date());
  const [data, setData] = React.useState({});

  const fetchData = async () => {
    const res = await dashboardReportApi({
      date: moment(filterDate).format('YYYY-MM')
    });
    setData(res);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page className={classes.root} title={t('overview_title')}>
      <Header
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        fetchData={fetchData}
      />
      <Statistics
        className={classes.statistics}
        totalPaid={data.total_paid}
        totalQuantity={data.total_quantity}
      />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={12} xl={12} xs={12}>
          <TableVertical list={data.list_top_data || []} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default Overview;
