/* eslint-disable react/no-multi-comp */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { colors } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';

export const FormatMoney = props => {
  const { inputRef, onChange, onBlur, name, placeholder, ...other } = props;
  const currency = useSelector(state => state.session.currency);

  return (
    <NumberFormat
      {...other}
      style={{
        textAlign: 'end'
      }}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
        onBlur({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
      prefix={placeholder || ` ${currency}`}
      decimalScale={2}
      fixedDecimalScale
      thousandSeparator
    />
  );
};

FormatMoney.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export const FormatNumber = props => {
  const { inputRef, onChange, onBlur, name, placeholder, ...other } = props;
  const currency = useSelector(state => state.session.currency);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
        onBlur({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
      prefix={placeholder || ` ${currency}`}
      decimalScale={2}
      thousandSeparator
    />
  );
};

FormatNumber.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export const FormatSuffix = props => {
  const { inputRef, onChange, onBlur, name, placeholder, ...other } = props;
  const currency = useSelector(state => state.session.currency);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
        onBlur({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
      suffix={placeholder || ` ${currency}`}
      thousandSeparator
      decimalScale={2}
    />
  );
};

FormatMoney.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export const formatMoneyText = (value, unit) => {
  const _value = value || 0;
  return (
    <NumberFormat
      displayType={'text'}
      prefix={unit || ' ฿'}
      thousandSeparator
      value={(Math.round(_value * 100) / 100).toFixed(2)}
    />
  );
};

const convertFormat = length => {
  if (length < 9) return '### ### ###';
  if (length < 11) return '### #### ###';
  else if (length === 11) return '(####) # ###-###';
  else return undefined;
};

export const FormatPhone = props => {
  const { inputRef, onChange, onBlur, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format={convertFormat(other.value.length)}
      getInputRef={inputRef}
      mask=" "
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
        onBlur({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
    />
  );
};

export const FormatYear = props => {
  const { inputRef, onChange, onBlur, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format="####"
      getInputRef={inputRef}
      mask=" "
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
        onBlur({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
    />
  );
};

export const FormatMonth = props => {
  const { inputRef, onChange, onBlur, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format="## ####"
      getInputRef={inputRef}
      mask=" "
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
        onBlur({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
    />
  );
};

export const FormatPhoneNumber = props => {
  const { value } = props;

  return (
    <NumberFormat
      displayType={'text'}
      format={convertFormat(value.length)}
      value={value}
    />
  );
};

FormatPhone.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export const roundTimeQuarterHour = () => {
  var timeToReturn = new Date();

  timeToReturn.setHours(0);
  timeToReturn.setSeconds(0);
  timeToReturn.setMinutes(0);
  return timeToReturn;
};

export const statusColor = {
  dms_reject: colors.red[600],
  farmshop_bank_confirm: colors.purple[600],
  on_progress: colors.green[300],
  completed: colors.green[600],
  farm_shop_reject: colors.red[600],
  inquiry_created: colors.orange[600],
  wait_for_update_fee: colors.yellow[600],
  wait_for_farmshop_confirm: colors.blue[600],
  dms_cofirm_delivery: colors.green[300],
  farm_shop_confirm_being_delivered: colors.green[300],
  farm_shop_confirm_order_payments: colors.green[300],
};

export const convertThaiDate = (date, format = 'DD-MM-YYYY') => {
  // if (Locale.get() === 'th') {
  //   return moment(date)
  //     .add(543, 'years')
  //     .format(format);
  // }
  if (!date) {
    return 'N/A';
  }
  let _date = date;

  if (typeof date == 'string') {
    _date = date + '+00:00';
  }

  return moment(_date).format(format);
};

export const convertThaiFullDate = (
  date,
  format = 'DD-MM-YYYY HH:mm:ss',
  addDate
) => {
  
  if (!date) {
    return 'N/A';
  }
  let _date = date;

  if (typeof date == 'string') {
    _date = date + '+00:00';
  }
  return moment(_date )
    .add(addDate || 0, 'day')
    .format(format);
};

export const convertThaiDateInput = (date, format = 'YYYY-MM-DD') => {
  // if (Locale.get() === 'th') {
  //   return moment(date)
  //     .add(543, 'years')
  //     .format(format);
  // }

  return moment(date).format(format);
};

export const convertVietDate = (date, format = 'YYYY-MM-DD') => {
  // if (Locale.get() === 'th') {
  //   return moment(date)
  //     .subtract(543, 'years')
  //     .format(format);
  // }

  return moment(date).format(format);
};

export function removeSpace(text) {
  if(!text) return ''
  return text.replace(/\s/g, '')
}
