import uuid from 'uuid/v1';
import moment from 'moment';

import mock from 'utils/mock';

mock.onGet('/api/management/products').reply(200, {
  products: [
    {
      id: uuid(),
      local_name: 'Ekaterina Tankova',
      product_image: '/images/avatars/avatar_1.png',
      status: 'active',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    },
    {
      id: uuid(),
      local_name: 'Cao Yu',
      email: 'cao.yu@devias.io',
      product_image: '/images/avatars/avatar_3.png',
      status: 'active',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    },
    {
      id: uuid(),
      local_name: 'Alexa Richardson',
      email: 'alexa.richardson@devias.io',
      product_image: '/images/avatars/avatar_4.png',
      status: 'inactive',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    },
    {
      id: uuid(),
      local_name: 'Anje Keizer',
      email: 'anje.keizer@devias.io',
      product_image: '/images/avatars/avatar_5.png',
      status: 'purged',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    },
    {
      id: uuid(),
      local_name: 'Clarke Gillebert',
      email: 'clarke.gillebert@devias.io',
      product_image: '/images/avatars/avatar_6.png',
      status: 'active',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    },
    {
      id: uuid(),
      local_name: 'Adam Denisov',
      email: 'adam.denisov@devias.io',
      product_image: '/images/avatars/avatar_7.png',
      status: 'active',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    },
    {
      id: uuid(),
      local_name: 'Ava Gregoraci',
      email: 'ava.gregoraci@devias.io',
      product_image: '/images/avatars/avatar_8.png',
      status: 'active',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    },
    {
      id: uuid(),
      local_name: 'Emilee Simchenko',
      email: 'emilee.simchenko@devias.io',
      product_image: '/images/avatars/avatar_9.png',
      status: 'active',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    },
    {
      id: uuid(),
      local_name: 'Kwak Seong-Min',
      email: 'kwak.seong.min@devias.io',
      product_image: '/images/avatars/avatar_10.png',
      status: 'active',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    },
    {
      id: uuid(),
      local_name: 'Shen Zhi',
      email: 'shen.zhi@devias.io',
      product_image: '/images/avatars/avatar_11.png',
      status: 'inactive',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    },
    {
      id: uuid(),
      local_name: 'Merrile Burgett',
      email: 'merrile.burgett@devias.io',
      product_image: '/images/avatars/avatar_12.png',
      status: 'active',
      price: '1000',
      inventory_num: '500',
      expiry_date: moment().add(15, 'days'),
    }
  ]
});
