/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { t } from 'locale';
import HomeIcon from '@material-ui/icons/HomeOutlined';
// import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import StoreIcon from '@material-ui/icons/Store';
import StorageIcon from '@material-ui/icons/Storage';
import PinDropIcon from '@material-ui/icons/PinDrop';
import ChatIcon from '@material-ui/icons/ChatOutlined';

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: t('overview'),
        href: '/overview',
        icon: HomeIcon
      },
      {
        title: t('create_product'),
        href: '/product/create',
        icon: PlaylistAddIcon
      },
      {
        title: t('product_management'),
        href: '/product-management',
        icon: StorageIcon
      },
      {
        title: t('order_management'),
        href: '/order-management',
        icon: ShoppingCartIcon
      },
      {
        title: 'Chat',
        href: '/chat',
        icon: ChatIcon,
      },
      {
        title: t('farm_shop_management'),
        href: '/farm-shop-management',
        icon: StoreIcon
      },
      {
        title: t('price_by_gps_management'),
        href: '/price-by-gps-management',
        icon: PinDropIcon
      },
     
      {
        title: t('settings'),
        href: '/settings',
        icon: SettingsIcon,
        children: [
          {
            title: t('seller_kyc'),
            href: '/kyc'
          },
          {
            title: t('general_setting'),
            href: '/settings/general'
          },
          {
            title: t('change_password'),
            href: '/settings/security'
          }
        ]
      },
      // {
      //   title: 'Authentication',
      //   href: '/auth',
      //   icon: LockOpenIcon,
      //   children: [
      //     {
      //       title: 'Login',
      //       href: '/auth/login'
      //     },
      //     {
      //       title: 'Register',
      //       href: '/auth/register'
      //     }
      //   ]
      // },
    ]
  }
];
