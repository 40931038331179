import { useDispatch, useSelector } from 'react-redux';
import {
  confirmTokenApi,
  getCurrentLocation,
  getUserInfo
} from 'apis';
import { setInfo, setLocation } from 'actions';
import React from 'react';

export const useInitStoreAndConfirmToken = async router => {
  const dispatch = useDispatch();
  const { user, location } = useSelector(state => state.session);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (!location) {
          const location = await getCurrentLocation();
          dispatch(setLocation(location));
        }
        await confirmTokenApi(router);
        const userName = window.localStorage.getItem('agrimomo_username');
        if (!user.username) {
          const userInfo = await getUserInfo(userName);
          dispatch(setInfo(userInfo));
        }
      } catch (error) {
        router.history.push('/auth/login');
      }
    };
    fetchData();
  }, [user, location]);
};
