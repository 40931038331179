import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import getInitials from 'utils/getInitials';
import { GenericMoreButton } from 'components';
import { t } from 'locale';
import { convertThaiDate, formatMoneyText } from 'utils/format';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 900
  },
  author: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(1)
  },
  tags: {
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const TableVertical = props => {
  const { className, list = [], ...rest } = props;
  const currentCurrency = useSelector(state => state.session.currency);

  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader action={<GenericMoreButton />} title={t('dashboard')} />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollY: true }}>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('product_name')}</TableCell>
                  <TableCell>{t('buyer_name')}</TableCell>
                  {/* <TableCell>{t('phone_number')}</TableCell> */}
                  <TableCell>{t('total_price')}</TableCell>
                  <TableCell>{t('total_quantity')}</TableCell>
                  <TableCell>{t('date')}</TableCell>
                  <TableCell align="center">{t('action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map(project => (
                  <TableRow hover key={project.id}>
                    <TableCell>{project.product_name}</TableCell>
                    <TableCell>
                      <div className={classes.author}>
                        <Avatar
                          alt="Author"
                          className={classes.avatar}
                          src={project.order_img}>
                          {getInitials(project.buyer_name)}
                        </Avatar>
                        {project.buyer_name}
                      </div>
                    </TableCell>
                    {/* <TableCell>{project.phone_number}</TableCell> */}
                    <TableCell>
                      {formatMoneyText(project.total_price, ` ${currentCurrency}`)}
                    </TableCell>
                    <TableCell>{project.total_quantity}</TableCell>
                    <TableCell>{convertThaiDate(project.create_time, 'HH:mm:ss DD-MM-YYYY')}</TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to={`/order/edit/${project.order_id}`}
                        variant="outlined">
                        {project.order_id}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="/order-management"
          variant="text">
          {t('see_all')}
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions>
    </Card>
  );
};

TableVertical.propTypes = {
  className: PropTypes.string
};

export default TableVertical;
