import uuid from 'uuid/v1';
import moment from 'moment';

import mock from 'utils/mock';

mock.onGet('/api/management/orders').reply(200, {
  orders: [
    {
      id: uuid(),
      local_name: 'Product name 1',
      product_image: '/images/avatars/avatar_1.png',
      order_image: '/images/covers/cover_1.png',
      order_name: 'Tung',
      order_phone_number: '0123456789',
      order_number: '3',
      status: 'order waiting for confirmation',
      last_status: moment().add(15, 'days'),
      total_payment: '1000',
      created_date: moment().add(15, 'days')
    },
    {
      id: uuid(),
      local_name: 'Product name 2',
      product_image: '/images/avatars/avatar_2.png',
      order_image: '/images/covers/cover_2.png',
      order_name: 'Tung',
      order_phone_number: '0123456789',
      order_number: '2',
      status: 'processing',
      last_status: moment().add(15, 'days'),
      total_payment: '1000',
      created_date: moment().add(15, 'days')
    },
    {
      id: uuid(),
      local_name: 'Product name 3',
      product_image: '/images/avatars/avatar_3.png',
      order_image: '/images/covers/cover_1.png',
      order_name: 'Tung',
      order_phone_number: '0123456789',
      order_number: '1',
      status: 'delivering',
      last_status: moment().add(15, 'days'),
      total_payment: '1000',
      created_date: moment().add(15, 'days')
    },
    {
      id: uuid(),
      local_name: 'Product name 4',
      product_image: '/images/avatars/avatar_4.png',
      order_image: '/images/covers/cover_1.png',
      order_name: 'Tung',
      order_phone_number: '0123456789',
      order_number: '2',
      status: 'completed',
      last_status: moment().add(15, 'days'),
      total_payment: '1000',
      created_date: moment().add(15, 'days')
    },
    {
      id: uuid(),
      local_name: 'Product name 5',
      product_image: '/images/avatars/avatar_5.png',
      order_image: '/images/covers/cover_2.png',
      order_name: 'Tung',
      order_phone_number: '0123456789',
      order_number: '3',
      status: 'cancelled',
      last_status: moment().add(15, 'days'),
      total_payment: '1000',
      created_date: moment().add(15, 'days')
    },
    {
      id: uuid(),
      local_name: 'Product name 6',
      product_image: '/images/avatars/avatar_6.png',
      order_image: '/images/covers/cover_2.png',
      order_name: 'Tung',
      order_phone_number: '0123456789',
      order_number: '3',
      status: 'report',
      last_status: moment().add(15, 'days'),
      total_payment: '1000',
      created_date: moment().add(15, 'days')
    }
  ]
});
