import uuid from 'uuid/v1';

import mock from 'utils/mock';

mock.onGet('/api/management/farm-shop').reply(200, {
  farms: [
    {
      id: uuid(),
      farm_name: 'farm shop 1',
      level: '5',
      status: 'active',
      contact: 'Mr Nguyen Son Tung',
      total_order_amount: '500',
      orders: '500',
      last_order: '500'
    },
    {
      id: uuid(),
      farm_name: 'farm shop 2',
      level: '1',
      status: 'inactive',
      contact: 'Mr Nguyen Son Tung',
      total_order_amount: '500',
      orders: '500',
      last_order: '500'
    },
    {
      id: uuid(),
      farm_name: 'farm shop 3',
      level: '2',
      status: 'active',
      contact: 'Mr Nguyen Son Tung',
      total_order_amount: '500',
      orders: '500',
      last_order: '500'
    },
    {
      id: uuid(),
      farm_name: 'farm shop 4',
      level: '5',
      status: 'active',
      contact: 'Mr Nguyen Son Tung',
      total_order_amount: '500',
      orders: '500',
      last_order: '500'
    },
    {
      id: uuid(),
      farm_name: 'farm shop 5',
      level: '3',
      status: 'active',
      contact: 'Mr Nguyen Son Tung',
      total_order_amount: '500',
      orders: '500',
      last_order: '500'
    },
    {
      id: uuid(),
      farm_name: 'farm shop 6',
      level: '1',
      status: 'inactive',
      contact: 'Mr Nguyen Son Tung',
      total_order_amount: '500',
      orders: '500',
      last_order: '500'
    }
  ]
});
