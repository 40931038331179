import React from 'react';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/styles';
import TranslateIcon from '@material-ui/icons/Translate';
import Locale, { t, languageMap } from 'locale';
import useRouter from 'utils/useRouter';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  selectButton: {
    color: theme.palette.white,
    fontWeight: 'bold'
  },
  langIcon: {
    marginRight: theme.spacing(1)
  }
}));

const LanguageSelect = () => {
  const classes = useStyles();
  const router = useRouter();
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const lang = Locale.currLocale;

  const setLanguage = lang => {
    Locale.setLang(lang);
    window.location.reload();
  };

  React.useEffect(() => {
    console.log(111, router.location.search);
    if (router?.location?.search?.includes('lang=')) {
      const urlParams = new URLSearchParams(router?.location.search);
      const requiredLang = urlParams.get('lang');
      if (['en', 'th', 'vi'].includes(requiredLang)) {
        Locale.setLang(requiredLang);
      }
    }
  }, [router]);

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <Button
        className={classes.selectButton}
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        <TranslateIcon className={classes.langIcon} />
        {languageMap[lang]}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={() => setMenuAnchor(null)}
        open={!!menuAnchor}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <List>
          <ListSubheader>{t('select_language')}</ListSubheader>
          {Object.keys(languageMap).map(item => (
            <ListItem
              button
              key={item}
              onClick={() => {
                setMenuAnchor(null);
                setLanguage(item);
              }}>
              {languageMap[item]}
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default LanguageSelect;
