import { request } from 'utils/axios';

function handleError(message, error) {
  if (error.response) {
    console.log(message, error.response.data);
    return error.response.data;
  }
  console.log(message, error);
  return { status: 500, message: '' };
}

export async function loginApi(userName, password) {
  const response = await request.request({
    method: 'POST',
    url: '/wholesale/login',
    data: {
      username: userName,
      password: password
    }
  });
  return response;
}

export async function registerApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/wholesale/create',
    data
  });
  return response;
}

export async function getUserInfo() {
  const response = await request.request({
    method: 'GET',
    url: '/wholesale/get_info'
  });
  return response.data;
}

export async function confirmTokenApi() {
  const response = await request.request({
    method: 'GET',
    url: '/wholesale/validate'
  });
  return response;
}

export function uploadImageApi(files, onUploadProgress) {
  try {
    let formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    return request.post('/product/upload_image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadImageApi', error);
  }
}

export async function updateUserInfoApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/wholesale/update',
    data
  });
  return response.data;
}

export function getDataLocation(link) {
  return new Promise(resolve => {
    fetch(link)
      .then(x => x.json())
      .then(data => resolve(data))
      .catch(() => resolve([]));
  });
}

export function getDataOrigin() {
  return new Promise(resolve => {
    fetch(
      `https://${process.env.REACT_APP_AWS_BUCKET}.s3.ap-southeast-1.amazonaws.com/list_countries2.json`
    )
      .then(x => x.json())
      .then(data => resolve(data))
      .catch(() => resolve([]));
  });
}

export async function changePasswordApi(password) {
  const response = await request.request({
    method: 'PUT',
    url: '/wholesale/change_password',
    data: {
      password
    }
  });
  return response.data;
}

export async function createProductApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/product/create',
    data
  });
  return response.data;
}

export async function updateProductApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/product/update',
    data
  });
  return response.data;
}

export async function getProductApi(id) {
  const response = await request.request({
    method: 'POST',
    url: '/product/get_info',
    data: { id }
  });
  return response.data;
}

export async function deleteProductApi(id) {
  const response = await request.request({
    method: 'POST',
    url: '/product/delete',
    data: { id }
  });
  return response.data;
}

export async function listProductApi(type, num = 1) {
  const response = await request.request({
    method: 'POST',
    url: '/product/get_list',
    data: {
      num,
      product_type: type
    }
  });
  return response.data;
}

export async function verifyPhoneApi(username, code) {
  const response = await request.request({
    method: 'POST',
    url: '/sms_verify/verify',
    data: {
      username,
      code
    }
  });
  return response.data;
}

export async function listOrderApi({ start_date, end_date, type }) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms/get_list',
    data: {
      start_date,
      end_date,
      type
    }
  });
  return response.data;
}

export async function orderDetailApi(order_id) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/get_order_detail',
    data: {
      order_id
    }
  });
  return response.data;
}

export async function updateLogisticFeeApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_update_logistic_fee',
    data
  });
  return response.data;
}

export async function updateInvoiceApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_update_invoice',
    data
  });
  return response.data;
}

export async function updateReceiptApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_update_receipt',
    data
  });
  return response.data;
}

export async function confirmDeliveryApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_confirm_delivery',
    data
  });
  return response.data;
}

export async function confirmFarmshopPaidApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_confirm_farmshop_paid',
    data
  });
  return response.data;
}

export async function confirmFarmshopTransferredApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_confirm_farmshop_transferred',
    data
  });
  return response.data;
}

export async function updatePaymentTerms(data) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_update_payment_terms',
    data
  });
  return response.data;
}

export async function confirmOrderApi(order_id) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_confirm_order',
    data: { order_id }
  });
  return response.data;
}

export async function rejectOrderApi(order_id, message) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_reject_order',
    data: {
      order_id,
      message
    }
  });
  return response.data;
}

export async function deliveryOrderApi(order_id) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_completed_order',
    data: { order_id }
  });
  return response.data;
}

export async function agentListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/agent/list_agent'
  });
  return response.data;
}

export async function createAgentAccountApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/create_account',
    data
  });
  return response.data;
}

export async function updateAgentLevelApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/update_agent_level',
    data
  });
  return response.data;
}

export async function deleteAgentLevelApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/remove_relation',
    data
  });
  return response.data;
}

export async function dashboardReportApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/report/dms_report',
    data
  });
  return response.data;
}

export const getCurrentLocation = async () => {
  const response = await request.request({
    method: 'POST',
    url: '/location/get_data'
  });
  return response.data;
};

export async function priceByGpsListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/agent/list_price_by_gps'
  });
  return response.data;
}

export async function createPriceByGpsApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/create_location_agent_price',
    data
  });
  return response.data;
}

export async function updatePriceByGpsApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/update_gps_price',
    data
  });
  return response.data;
}

export async function deleteLocationApi(gps_price_id) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/delete_location',
    data: { gps_price_id }
  });
  return response.data;
}

export async function getListChoice() {
  const response = await request.request({
    method: 'POST',
    url: '/product/get_choice'
  });
  return response.data;
}

export async function addOptionChoice(data) {
  const response = await request.request({
    method: 'POST',
    url: '/product/add_common_name_data',
    data
  });
  return response.data;
}

export async function getKYCdata(data) {
  const response = await request.request({
    method: 'POST',
    url: '/kyc/get_seller_kyc_data',
    data
  });
  return response.data;
}

export async function submitKyc(data) {
  const response = await request.request({
    method: 'POST',
    url: '/kyc/update_seller_kyc',
    data
  });
  return response.data;
}

export async function forgotPasswordApi(username) {
  const response = await request.request({
    method: 'POST',
    url: '/sms_verify/send_forget_password',
    data: {
      username
    }
  });
  return response.data;
}

export async function changeOtpApi(username, code) {
  const response = await request.request({
    method: 'POST',
    url: '/sms_verify/verify_new_password',
    data: {
      username,
      code
    }
  });
  return response.data;
}

export async function getKgpProfessions() {
  const response = await request.request({
    method: 'GET',
    url: 'dms/kgp/professions'
  });

  return response.data;
}

export async function getKgpOccupations() {
  const response = await request.request({
    method: 'GET',
    url: '/dms/kgp/occupations'
  });

  return response.data;
}

export async function getKgpBanks() {
  const response = await request.request({
    method: 'GET',
    url: '/dms/kgp/banks'
  });

  return response.data;
}

export async function mutationOtpCode() {
  const response = await request.request({
    method: 'POST',
    url: '/dms/kgp/onboard'
  });

  return response.data;
}

export async function mutationVerifyOtp(otp) {
  const response = await request.request({
    method: 'POST',
    url: '/dms/kgp/onboard/verify',
    data: {
      otp
    }
  });

  return response.data;
}

export async function updateDeliveryTerm(order_id, data) {
  const response = await request.request({
    method: 'POST',
    url: `/dms/order_managements/${order_id}/delivery_term`,
    data
  });
  return response.data;
}
