export function getUrlExtension(url) {
  return url
    .split(/[#?]/)[0]
    .split('.')
    .pop()
    .trim();
}
export function getFileNameFromURI(uri) {
  return uri.split('/').pop();
}
